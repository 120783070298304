<template>
  <div class="w-100 text-center mt-4">
      <img alt="Logo" height="60" src="@/assets/logo.svg">
  </div>
  <div id="register" class="card mt-3">
    <div class="card-body">
      <template v-if="view=='register'">
        <div v-if="error" class="alert alert-danger">Email incorrect</div>
        <h1 class="text-center h3">Sign Up</h1>
        <form @submit.prevent="submit()">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="email">Email</label>
                <input type="text" class="form-control" id="email" v-model.trim="v$.email.$model">
                <div v-if="v$.email.$dirty">
                  <small class="form-text text-danger" v-if="v$.email.required.$invalid">Required field</small>
                  <small class="form-text text-danger" v-if="v$.email.email.$invalid">Format incorrect</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" v-model.trim="v$.password.$model">
                <div v-if="v$.password.$dirty">
                  <small class="form-text" v-if="v$.password.awsRegex.$invalid">
                    <span class="text-danger mr-2">Format invalid.</span>
                    <HelpInfo :text="'<p>Password must contain:</p><ul><li>Minimum length of 8 characters</li><li>Contains at least 1 number</li><li>Contains at least one lowercase letter</li><li>Contains at least one capital letter</li><li>Contains at least 1 special character from the following set or a space character that is neither leading nor trailing: ^ $ * . [ ] { } ( ) ? - &quot; ! @ # % & &#47; &#92; &#44; &gt; &lt; : &#59; | _ ~ ` + = </li></ul>'">
                      
                    </HelpInfo>
                  </small>
                  <small class="form-text text-danger" v-if="v$.password.required.$invalid">Required field</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <label for="rpassword">Repeat Password</label>
                <input type="password" class="form-control" id="rpassword" v-model.trim="v$.rpassword.$model">
                <div v-if="v$.rpassword.$dirty">
                  <small class="form-text text-danger" v-if="v$.rpassword.sameAs.$invalid">Passwords do not match</small>
                  <small class="form-text text-danger" v-if="v$.rpassword.required.$invalid">Required field</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-right">
              <button class="btn btn-primary" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
                <span v-if="!loading">Sign Up</span>
                <img v-if="loading" src="@/assets/loader.svg" height="20" alt=""></button>
            </div>
          </div>
        </form>
      </template>
      <template v-if="view=='confirmCode'">
        <div v-if="errorCode" class="alert alert-danger">Invalid Verification Code. Try again.</div>
        <form @submit.prevent="confirmCode()">
          <div class="row">
            <div class="col-12">
              <p>We have sent you a verification code to your email. Please log in to your email and enter the code you received.</p>
              <div class="form-group mb-0">
                <label for="code">Verification Code</label>
                <div class="position-relative">
                  <input type="text" class="form-control" id="code" v-model.trim="v$.code.$model">
                  <span v-if="!loadingResend" class="btn-link position-absolute resendCode" @click="resendCode()">Resend code</span>
                  <span v-if="loadingResend" class="resendCode position-absolute"><img src="@/assets/loader.svg" height="20" alt=""/></span>
                </div>
                <div v-if="v$.code.$dirty">
                  <small class="form-text text-danger" v-if="v$.code.required.$invalid">Required field</small>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-right">
              <button class="btn btn-primary" v-bind:class="{ disabled: v$.$invalid }" :disabled="v$.$invalid">
                <span v-if="!loading">Verify</span>
                <img v-if="loading" src="@/assets/loader.svg" height="20" alt="">
              </button>
            </div>
          </div>
        </form>
      </template>
      <template v-if="view=='confirmation'">
        <div class="row">
            <div class="col-12 text-center">
              <p>You have successfully registered. You can now log in.</p>
              <router-link class="btn btn-primary" :to="{ name: 'login' }"> Sign In </router-link>
            </div>
          </div>
      </template>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, email, requiredIf, helpers, sameAs  } from '@vuelidate/validators'
import HelpInfo from '../Shared/HelpInfo.vue';

const awsRegex = helpers.regex(/^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/)

export default {
  name: 'Login',
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      error: false,
      errorCode: false,
      view: 'register',
      rpassword: '',
      session: '',
      loadingResend: false
    };
  },
  components: {
    HelpInfo
  },
  created() {
     this.$emit('widgetLoaded');
  },
  mounted(){
  },
  validations () {
    return {
      email: {
        required,
        email
      },
      password: {
        awsRegex,
        required: requiredIf(function(){ return this.view === 'register' }),
      },
      rpassword: {
        awsRegex,
        sameAs: sameAs(this.password),
        required: requiredIf(function(){ return this.view === 'register' }),
      },
      code: {
        awsRegex,
        required: requiredIf(function(){ return this.view === 'confirm' }),
      }
    }
  },
  methods: {

    submit() {
      const bodyToSend = {
        email: this.email,
        password: this.password,
      };
      this.loading = true;
      this.error = false;

      axios.post('/cryptos/auth/register', bodyToSend)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if(response.data.statusCode !== 200){
            this.resendCode();
          }
          
          this.view = 'confirmCode';
          
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    confirmCode(){
      const bodyToSend = {
        email: this.email,
        code: this.code,
      };
      this.loading = true;
      this.errorCode = false;

      axios.post('/cryptos/auth/register/confirm', bodyToSend)
        .then((response) => {
          this.loading = false;
          if(response.data.body.response.$metadata.httpStatusCode !== 200){
            this.errorCode = true;
          }
          else{
            this.confirmOk();
          }
        });
    },
    confirmOk(data){
      this.view = 'confirmation';
    },
    resendCode(){
      this.loadingResend = true;
      const bodyToSend = {
        email: this.email,
      };

      axios.post('/cryptos/auth/register/resendCode', bodyToSend)
        .then((response) => {
          this.loadingResend = false;
        });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  .resendCode{
    right: 0.75rem;
    top: 0.65rem;
    font-size: 0.8rem;
    cursor: pointer;
    font-weight: 500;
  }

</style>
